<script lang="ts" setup>
useHead({
  meta: [
    { name: 'ahrefs-site-verification', content: 'f8f3f12ecdea01cac5531f59cf6aad1313d2527ad6357bad6d45b47211b81894' }
  ]
})
</script>

<script lang="ts">
export default {
  name: 'ErrorLayout'
}
</script>

<template>
  <section class="w-screen h-screen py-32 px-24 flex flex-col items-center justify-center">
    <slot />
  </section>
</template>
